<template>
  <div>
    <div v-if="selectedStudentWeekPlan !== null" style="position: relative;" class="rounded shadow-sm bg-white mt-3 pt-2">
      <div id="student-week-plan" :data-key="selectedStudentWeekPlan.key"></div>
      <button @click="showOverview" class="btn btn-outline-primary btn-sm" style="position: absolute; top: 10px; left: 10px;">
        <i class="fas fa-chevron-left"></i> Overzicht
      </button>
      <div class="text-center">
        <h3>{{selectedStudentWeekPlan.student.name}}</h3>
      </div>
      <pre v-if="false">{{selectedStudentWeekPlan}}</pre>
      <div class="text-center w-100">
          <button @click="clearChats" v-if="selectedStudentWeekPlan.messages.length" class="btn btn-sm btn-outline-primary">Chatgeschiedenis van deze week wissen</button>
      </div>
      <div class="text-center w-100">
        <span v-if="selectedStudentWeekPlan.feedback.feeling.frown > 0" class="badge badge-danger"><i class='fa fa-frown'></i> {{selectedStudentWeekPlan.feedback.feeling.frown }}</span>
        <span v-if="selectedStudentWeekPlan.feedback.feeling.meh > 0" class="badge badge-warning text-light"><i class='fa fa-meh'></i> {{selectedStudentWeekPlan.feedback.feeling.meh }}</span>
        <span v-if="selectedStudentWeekPlan.feedback.feeling.smile > 0" class="badge badge-success"><i class='fa fa-smile'></i> {{selectedStudentWeekPlan.feedback.feeling.smile }}</span>
        <span v-if="selectedStudentWeekPlan.feedback.result.down > 0" class="badge badge-danger"><i class='fa fa-thumbs-down'></i> {{selectedStudentWeekPlan.feedback.result.down }}</span>
        <span v-if="selectedStudentWeekPlan.feedback.result.up > 0" class="badge badge-success"><i class='fa fa-thumbs-up'></i> {{selectedStudentWeekPlan.feedback.result.up }}</span>
      </div>
      <div class="text-center w-100 px-5 border-bottom-1 mb-2">
        <div class="progress mt-1">
          <div class="progress-bar bg-success" role="progressbar" :style="`width: ${selectedStudentWeekPlan.progress}%;`">{{selectedStudentWeekPlan.progress}}%</div>
        </div>      
      </div>
      <chatView :uuid="uuid" :use_fileupload="use_fileupload" :loading="chatLoading" scope="user"></chatView>
    </div>

    <div v-if="selectedStudentWeekPlan === null">
      <div class=" rounded shadow-sm bg-white mt-3 pt-3 pb-3 px-4 d-flex justify-content-between">
        <p class="lead my-0">Leerlingen loggen in via: mijnweektaak.com</p>
        <a :href="`/groups/${group.id}`" class="btn btn-sm btn-outline-primary">Inlogcodes veranderen</a>

        <div class="alert alert-secondary" v-if="false && use_qr">
        
        <p>Wist je dat Weektaak.com ook sleutelhangers levert met daarop een QR-code? Met zo'n sleutelhanger kan de leerling supersnel inloggen op mijnweektaak.com. <a target="_blank" href="/handleiding/QRcode-sleutelhanger">Lees meer</a></p>  
        {{ school }}
        </div>
      </div>

      <div class="nav nav-tabs mt-3" style="border-bottom: 0;">
        <div class="nav-item">
          <button @click="selectTab('all')" class="nav-link" v-bind:class="{'active': selectedTab == 'all'}">Overzicht</button>
        </div>
        <div class="nav-item">
          <button @click="selectTab('list')" class="nav-link" v-bind:class="{'active': selectedTab == 'list'}">Aftekenlijst</button>
        </div>
        <div class="nav-item" v-if="has_autonomous_lessons">
          <button @click="selectTab('planned')" class="nav-link" v-bind:class="{'active': selectedTab == 'planned'}">Zelfgeplande taken</button>
        </div>
      </div>
      <div class=" rounded shadow-sm bg-white mt-0 pb-4" :class="{'border': selectedTab == 'all'}">
        <div v-if="selectedTab == 'planned'">
          <div class="py-5 text-center" v-if="planningLoading"> 
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <table v-if="!planningLoading" class="table table-striped table-bordered border-0" >
            <thead>
              <tr>
                <td></td>
                <td v-for="day in planningData.days">{{day.day }}</td>
                <td>Niet gepland</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="student in planningData.students">
                <td>{{student.name}}</td>
                <td v-for="day in student.days" style="padding: 0;">
                  
                    <div :class="{'p-1 small task-detail': true, 'bg-success' : lesson.completed, 'bg-warning': !lesson.completed, 'extra':lesson.is_extra}" v-for="lesson in day.lessons" @click="showPlannedLessonDetails(lesson)">
                      {{ lesson.course }}
                  </div>
                </td>
                <td style="padding: 0;">
                  <div :class="{'p-1 bg-danger text-white small task-detail': true, 'extra': lesson.is_extra}" v-for="lesson in student.unplanned_lessons" @click="showPlannedLessonDetails(lesson)">
                      {{ lesson.course }}
                    </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="selectedTab == 'list'">
          <div class="py-5 text-center" v-if="listLoading"> 
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <table v-if="!listLoading" class="table table-striped table-bordered border-0" >
            <thead>
              <tr>
                <td></td>
                <td v-for="day in listData.days">{{day.day }}</td>
                <td>{{klaaropdracht}}</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="student in listData.students">
                <td>{{student.name}}</td>
                <td v-for="day in student.days" style="padding: 0;">
                  
                    <div :class="{'p-1 small task-detail': true, 'bg-success' : lesson.completed, 'bg-warning': !lesson.completed, 'extra':lesson.is_extra}" v-for="lesson in day.lessons" @click="showPlannedLessonDetails(lesson)">
                      {{ lesson.course }}
                  </div>
                </td>
                <td style="padding: 0;">
                  <div :class="{'p-1 small task-detail': true, 'bg-success' : lesson.completed, 'bg-warning': !lesson.completed, 'extra':lesson.is_extra}" v-for="lesson in student.extra_tasks" @click="showPlannedLessonDetails(lesson)">
                      {{ lesson.course }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <table :class="{'table':true}" v-if="selectedTab == 'all'" >
          <thead>
            <tr>
              <th></th>
              <th>Naam</th>
              <th>Voortgang</th>
              <th class="text-center">
                PIN <i @click="showPinModal()" class="fas fa-info-circle pt-1"></i>
              </th>
              <th class="text-center">Feedback</th>
              <th v-if="weekPlanSettings.use_credits" class="text-center">
                <i class="text-warning fas fa-coins"></i>
              </th>
              <th v-if="weekPlanSettings.use_chat">
                <div class="btn-group">
                  <button @click="showGroupMessageModal()" class="btn btn-outline-primary btn-sm">Groepsbericht</button>
                  <button type="button" class="btn-sm btn btn-outline-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="sr-only">Toggle Dropdown</span></button>
                  <div class="dropdown-menu">
                    <button @click="showDeleteChatModal()" class="dropdown-item">Geschiedenis wissen</button>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <studentweekplan @selectStudent="setSelectedStudent" v-for="student_week_plan in studentWeekPlans" :studentweekplan="student_week_plan" :key="student_week_plan.id"></studentweekplan>
          </tbody>
        </table>
        <div class="modal fade" id="pin-modal" tabindex="-1" role="dialog" aria-labelledby="pin-modalLabel" aria-hidden="true">
          <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
              <div class="model-body p-3">
                <p>
                  Iedere leerling heeft een eigen PIN code om in te loggen op mijnweektaak.com.
                </p>
                <p>Tip: bij het tabblad <span class="badge badge-light"><i class="fas fa-print"></i> Printen</span> kun je een overzicht uitprinten van de codes van alle leerlingen.</p>
                <div class="text-center" v-if="false">
                  <button v-if="!changePincCodes" class='btn btn-outline-primary btn-sm' @click="changePinCodes = true">PIN codes veranderen</button>
                  <button v-if="!changePincCodes" class='btn btn-outline-primary btn-sm' @click="changePinCodes = true">PIN codes veranderen</button>
                  <button class='btn btn-outline-primary btn-sm' @click="changePinCodes = true">PIN codes veranderen</button>
                </div>
                <div class="row" v-if="changePinCodes">
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="chat-delete-modal" tabindex="-1" role="dialog">
          <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Chatgeschiedenis wissen</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="model-body p-3">
                <p v-if="messagesDeleted" class="lead text-center"><span style="font-size: 60px;">🧹</span><br>De chatgeschiednies van {{ group.name_with_group }} is gewist!</p>
                <div class="form-group" v-if="!messagesDeleted">
                  <p class="lead">Wil je de chatgeschiedenis van {{ group.name_with_group }} wissen? Klik dan op de knop hieronder. Alle chatberichten van alle leerlingen die op dit moment in deze groeps zitten, worden dan gewist, inclusief eventuele bijlagen (foto's).<br><br><b>Let op:</b> deze handeling is niet ongedaan te maken.</p>
                </div>
                <div class="text-center" v-if="!messagesDeleted">
                  <button @click="deleteChats" :disabled="deleting" class="btn btn-danger">Wis chatgeschiedenis van {{ group.name_with_group }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="modal fade" id="group-message" tabindex="-1" role="dialog">
          <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Stuur een bericht naar alle leerlingen in één keer</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="model-body p-3">
                <p v-if="messageSent" class="lead">Het bericht is verstuurd naar alle leerlingen.</p>
                <div class="form-group" v-if="!messageSent">
                  <label for="exampleFormControlTextarea1">Bericht</label>
                  <textarea class="form-control" v-model="groupMessage" rows="3"></textarea>
                </div>
                <div class="form-group" v-if="!messageSent">
                  <button @click="sendGroupMessage" class="btn btn-primary" v-bind:disabled="groupMessage == ''">Verstuur bericht naar {{ studentweekplans.length }} leerlingen</button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="modal fade" id="planned-lesson-detail" tabindex="-1" role="dialog">
          <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title"><b v-if="selectedLesson.is_extra">{{klaaropdracht}}: </b>{{ selectedLesson.course }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="model-body p-3">
                <div v-if="selectedLesson.task">
                  <h4 class="small">Taak</h4>
                  <div v-html="selectedLesson.task"></div>
                </div>
                <div v-if="selectedLesson.goal">
                  <h4 class="small">Doel</h4>
                  <div v-html="selectedLesson.goal"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import chatView from '../../components/digital/chatView.vue'
export default {

  props: ["uuid", "studentweekplans", "weekplan", "klaaropdracht", "use_qr", "url", "url_without_protocol", "use_fileupload", "group", "unreadmessages", "has_autonomous_lessons"],
  components: {chatView},
  data () {
    return {
      chatLoading: true,
      selectedStudentWeekPlan: null,
      groupMessage: '',
      messageSent: false,
      selectedTab: 'all',
      deleting: false,
      messagesDeleted: false,
      changePinCodes: false,
      planningData: null,
      listData: null,
      planningLoading: true,
      listLoading: true,
      selectedLesson: {}
    }
  },
  created(){
    window.store.dispatch('setStudentWeekPlans', this.studentweekplans)
    window.store.dispatch('setWeekPlanSettings', this.weekplan)
    window.store.dispatch('fetchChatMessages', this.uuid)
  },
  computed: {
    allUnreadMessages() {
      // return window.store.getters.unreadChats
      return window.store.getters.chats
    },
    studentWeekPlans () {
      // return window.store.getters.digitalWeekPlan
      return window.store.getters.studentWeekPlans
    },
    weekPlanSettings (){
      return window.store.getters.weekPlanSettings
    }
  },

  methods: {
    selectTab(tab) {
      this.selectedTab = tab
      if (this.selectedTab == 'planned' && this.planningData == null) {
        this.fetchPlanningData()
      }
      if (this.selectedTab == 'list' && this.planningData == null) {
        this.fetchListData()
      }
    },
    deleteChats() {
      this.deleting = true
      return axios.post(`/week_plans/${this.weekplan.id}/delete_all_chats.json`)
      .then(response => {
        this.messagesDeleted = true        
        window.store.dispatch('setStudentWeekPlans', response.data)
        setTimeout(function () {
            this.messagesDeleted = false
            this.deleting = false
          }.bind(this), 10000)
        // window.store.dispatch('setDigitalWeekPlanMessages', [])
      })
    },
    sendGroupMessage() {
      return axios.post(`/week_plans/${this.weekplan.id}/create_group_message.json`, {message: this.groupMessage})
        .then(response => {
          this.messageSent = true
          setTimeout(function () {
            this.groupMessage = ''
            this.messageSent = false
          }.bind(this), 3500)
          
          window.store.dispatch('setStudentWeekPlans', response.data)
          // window.store.dispatch('setDigitalWeekPlanMessages', [])
        })
      },
    clearChats(){
      return axios.get(`/student_week_plans/${this.selectedStudentWeekPlan.id}/delete_messages.json`)
        .then(response => {
          window.store.dispatch('setDigitalWeekPlanMessages', [])
        })
    },
    fetchPlanningData() {
      return axios.get(`/week_plans/${this.weekplan.id}/planned_lessons.json`)
        .then(response => {
          this.planningData = response.data
          this.planningLoading = false
        })
    },
    fetchListData() {
      return axios.get(`/week_plans/${this.weekplan.id}/progress.json`)
        .then(response => {
          this.listData = response.data
          this.listLoading = false
        })
    },
    showOverview(){
      this.selectedStudentWeekPlan = null
      this.chatLoading = true
    },
    setSelectedStudent(studentweekplan){
      this.selectedStudentWeekPlan = studentweekplan
      window.store.dispatch('setDigitalWeekPlan', studentweekplan)
      window.store.dispatch('setDigitalWeekPlanMessages', studentweekplan.messages)
      setTimeout(function () {
        this.chatLoading = false
        }.bind(this), 1200)
    },
    showPinModal(){
      $("#pin-modal").modal('show')
    },
    showGroupMessageModal(){
      $("#group-message").modal('show') 
    },
    showPlannedLessonDetails(lesson){
      this.selectedLesson = lesson
      $("#planned-lesson-detail").modal('show')
    },
    showDeleteChatModal() {
      $("#chat-delete-modal").modal('show') 
    }
  }
}
</script>

<style lang="css" scoped>
  table.table th{
    border-top: 0;
  }
  .nav-item {
    margin-right:  4px;
  }
  .task-detail {
    border:  0;
    cursor:  pointer;
    border-radius: 4px;
    margin:  1px;
    padding-top:  1px !important;
    padding-bottom:  1px !important;
    border-left: 3px solid transparent;
  }
  .task-detail.extra {
    border-left: 3px solid black;
  }
</style>